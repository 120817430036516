//
// Scroll mixins
//

@mixin scrollbar-color($color, $hover-color: null) {
    // Firefox
    scrollbar-color: $color transparent;

    // Webkit
    &::-webkit-scrollbar-thumb {
        background-color: $color;
    }

    @if ($hover-color != null) {
        // Hover state
        &:hover {
            // Firefox
            scrollbar-color: #b6babd transparent;

            // Webkit
            &::-webkit-scrollbar-thumb {
                background-color: #b6babd;
            }
        }
    }
}
