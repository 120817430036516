@import "src/assets/sass/_base-moonflow.scss";


.btn.btn-principal {
  font-size: inherit;
  background-color: $color-primary;
  color: #fff;
  display: inline-flex;
  align-items: center;
  height: $min-height-btn-custom;
  border-radius: 5px;
  min-width: $min-width-btn;
  justify-content: center;
  padding: $padding-btn-custom;
  i{
    color: #fff;
  }
}

.btn.btn-principal:hover,.btn.btn-reverse:hover{
  color: #fff;
  box-shadow: #0000008c 0px 3px 8px;
}

.btn.btn-reverse{
  font-size: inherit;
  background-color: #fff ;
  color: $color-primary;
  border: 1px solid $color-primary;
  display: inline-flex;
  align-items: center;
  height: $min-height-btn-custom;
  border-radius: 5px;
  min-width: $min-width-btn;
  justify-content: center;
  padding: $padding-btn-custom;
  i{
    color: $color-primary;
  }
}

.btn.btn-reverse:hover{
  color: $color-primary!important;
}

.btn-icon {
  background-color: #00000000;
}
.btn-sm{
  font-size: 0.925rem!important;
}

.btn-icon.btn-tag-emoji{
  background-color: #00000000;
}

.btn-filter-option{
  min-height: calc(1.5em + 1.1rem + 2px);
}

.btn-filter-option-container{
  margin-top: 0.75rem !important;
}

.btn-action-mono{
  background-color: #e5e5e900;
  margin-right: 0.25rem !important;
  i{
    font-size: 1em!important;
  }
}
.btn-action-mono:hover{
  background-color: #E5E5E9;
  i{
    color: #040227;
  }
}

.btn.btn-icon-principal {
  // font-size: inherit;
  background-color: #040227;
  color: #fff;
  display: inline-flex;
  align-items: center;
  height: 40px;
  border-radius: 5px;
  justify-content: center;
}

.btn.btn-icon-principal:hover {
  box-shadow: #0000008c 0px 3px 8px;
}

.btn:focus {
  outline: 0;
  box-shadow: none;
}

.btn.btn-reverse.btn-filter-option.btn-download{
  min-width: 93px!important;
}
