//
// Theme style
//

// Mode
$mode: default;

// Initialize
@import "init";

// Components
@import "./core/components/components";
@import "components/components";

// Layout
@import "layout/layout";

// Angular vendors
@import "~socicon/css/socicon.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "~prism-themes/themes/prism-shades-of-purple.css";
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~animate.css/animate.css";
@import "./core/vendors/plugins/prismjs";
@import "./core/vendors/plugins/formvalidation";

@import "~@ng-select/ng-select/themes/default.theme.css";

@import "@ctrl/ngx-emoji-mart/picker";

@import "base-moonflow";

@import "src/assets/sass/moonflow/cards-custom.scss";
@import "src/assets/sass/moonflow/btn-custom.scss";

@import "src/assets/sass/moonflow/moonflow-modal.scss";
@import "src/assets/sass/_base-moonflow.scss";

* {
  font-size: 13px;
  font-family: 'Inter', sans-serif;
}

.fa-info-circle:before {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='11' viewBox='0 0 15 15'%3E%3Cg id='icon-información' transform='translate(-301.5 -320.5)'%3E%3Ccircle id='Elipse_3' data-name='Elipse 3' cx='7' cy='7' r='7' transform='translate(302 321)' fill='none' stroke='%23040227' stroke-width='1'/%3E%3Cpath id='Trazado_11921' data-name='Trazado 11921' d='M339.15,31l-2.026.279-.073.37.4.08c.259.069.311.171.254.456l-.653,3.372c-.171.872.094,1.282.715,1.282a1.751,1.751,0,0,0,1.3-.581l.077-.4a.926.926,0,0,1-.607.24c-.243,0-.33-.188-.269-.519Zm.061-2.03a1.024,1.024,0,0,1-.259.688.829.829,0,0,1-1.251,0,1.041,1.041,0,0,1,0-1.376.829.829,0,0,1,1.251,0A1.024,1.024,0,0,1,339.21,28.973Z' transform='translate(-29.349 295.603)' fill='%23040227'/%3E%3C/g%3E%3C/svg%3E");
}

.fa-info-circle.white:before {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='11' viewBox='0 0 15 15' fill='%23fff' stroke='%23fff'%3E%3Cg id='icon-información' transform='translate(-301.5 -320.5)'%3E%3Ccircle id='Elipse_3' data-name='Elipse 3' cx='7' cy='7' r='7' transform='translate(302 321)' fill='none' stroke='current' stroke-width='1'/%3E%3Cpath id='Trazado_11921' data-name='Trazado 11921' d='M339.15,31l-2.026.279-.073.37.4.08c.259.069.311.171.254.456l-.653,3.372c-.171.872.094,1.282.715,1.282a1.751,1.751,0,0,0,1.3-.581l.077-.4a.926.926,0,0,1-.607.24c-.243,0-.33-.188-.269-.519Zm.061-2.03a1.024,1.024,0,0,1-.259.688.829.829,0,0,1-1.251,0,1.041,1.041,0,0,1,0-1.376.829.829,0,0,1,1.251,0A1.024,1.024,0,0,1,339.21,28.973Z' transform='translate(-29.349 295.603)' fill='current'/%3E%3C/g%3E%3C/svg%3E");
}

.fa-info-circle.danger:before {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='11' viewBox='0 0 15 15' fill='%23EC5754' stroke='%23EC5754'%3E%3Cg id='icon-informaci%C3%B3n' transform='translate(-301.5 -320.5)'%3E%3Ccircle id='Elipse_3' data-name='Elipse 3' cx='7' cy='7' r='7' transform='translate(302 321)' fill='none' stroke='%23EC5754' stroke-width='1'/%3E%3Cpath id='Trazado_11921' data-name='Trazado 11921' d='M339.15,31l-2.026.279-.073.37.4.08c.259.069.311.171.254.456l-.653,3.372c-.171.872.094,1.282.715,1.282a1.751,1.751,0,0,0,1.3-.581l.077-.4a.926.926,0,0,1-.607.24c-.243,0-.33-.188-.269-.519Zm.061-2.03a1.024,1.024,0,0,1-.259.688.829.829,0,0,1-1.251,0,1.041,1.041,0,0,1,0-1.376.829.829,0,0,1,1.251,0A1.024,1.024,0,0,1,339.21,28.973Z' transform='translate(-29.349 295.603)' fill='%23EC5754'/%3E%3C/g%3E%3C/svg%3E");
}



.fab.field-management:before {
  content: url("../media/svg/warmi/field-management.svg");
  min-width: 10px;
  display: block;
}


body {
  color: $color-base;
  background-color: #ffffff;
}

$min-height-header-static : 4rem;

.sub-header-static {
  background: #ffffff;
  position: sticky;
  top: 0px;
  z-index: 102;
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
  min-height: $min-height-header-static;
}

.sub-header-static.with-breadcrumb {
  margin-top: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0.5rem !important;
  margin-bottom: 0rem !important;
}

.menu-sub-custom {
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #F2F2F4;
  border-radius: 5px;
  z-index: 101 !important;
}

$font-size-description : 11px;

.page-description {
  color: $disabled-color;
  font-weight: 500 !important;
  font-size: $font-size-description !important;
}

.form-custom-container {
  font-size: $font-size-description !important;

  div {
    font-size: $font-size-description !important;
  }
}

.form-custom-container-right {
  float: right;
}

.form-custom-error-container {
  font-size: $font-size-description !important;
  color: $color-danger !important;
}

.fv-plugins-message-container,
.form-error-container {
  font-size: $font-size-description !important;

  .fv-help-block {
    span {
      font-size: $font-size-description !important;
    }
  }
}


.header {
  z-index: 104 !important;
}

.text-dark {
  color: $color-base !important;
}

.aside.aside-dark {
  // background: linear-gradient(210deg, #040227 50%, #2c158f 80%, #bf3b6c 98%);
  background: #040227;
}

.aside.aside-dark .aside-logo {
  background-color: #040227 !important;
}

.aside-dark .menu .menu-item .menu-link.active {
  //background-color: #252c3c00!important;
}

.aside-dark .menu .menu-item.hover>.menu-link:not(.disabled):not(.active),
.aside-dark .menu .menu-item .menu-link:hover:not(.disabled):not(.active) {
  //background-color: #252c3c00!important;
}

.border-b-2px {
  border-bottom: 1px solid $color-base !important;
}

.border-t-1px {
  border-top: 1px solid $color-base !important;
}

.aside.aside-dark .aside-logo {
  background-color: #2e384d;
}

.aside-dark .menu .menu-item .menu-link.active {
  //background-color: #252c3c;
}

.aside-dark .menu .menu-item.hover>.menu-link:not(.disabled):not(.active),
.aside-dark .menu .menu-item .menu-link:hover:not(.disabled):not(.active) {
  //background-color: #252c3c;
}

.page-item.active .page-link {
  background-color: $color-primary;
}

.page-link:hover {
  color: $color-primary;
  background-color: #f1faff;
}

.input-group-text {
  // line-height: 1.75;
}

.form-control {
  color: $color-primary;
}

.form-select {
  color: $color-primary;
}

.form-control:focus {
  color: #181c32;
  border-color: $color-primary;
}

.form-select {
  cursor: pointer;
}

.form-select:focus {
  border-color: $color-primary;
}

.form-check .form-check-input:checked {
  background-color: $color-primary !important;
}

.warmi-primary-color {
  color: $color-primary !important;
}

.a-color {
  color: $color-primary !important;
}

.ng-select-custom {
  border-radius: 5px;
}

.ng-select-custom.disabled-intense .ng-select {
  background: #DADADA !important;
}




.bg-warmi-outlet-primary-color {
  background: $color-white;
  color: $color-primary !important;
  border-color: $color-primary !important;
  border: 2px solid !important;

  i {
    color: $color-primary !important;
  }
}

.bg-warmi-outlet-primary-color:hover {
  background: $color-primary;
  color: $color-white !important;
  box-shadow: #0000008c 0px 3px 8px;
  border: 2px solid $color-primary !important;

  i {
    color: $color-white !important;
  }
}



.bg-warmi-primary-color {
  background-color: $color-primary !important;
}

.bg-warmi-secondary-color {
  background-color: $color-secondary !important;
}

.bg-warmi-white-color {
  background-color: #ebf0ff !important;
}

.alert-notification-cotanier.warning {
  background: #FFFAD2 0% 0% no-repeat padding-box !important;
}

.alert-notification-cotanier.information {
  background: #EDF5FF 0% 0% no-repeat padding-box !important;
}

.alert-notification-cotanier.error{
  background: #FFEFF5 0% 0% no-repeat padding-box !important;
}

.warmi-muted-color {
  color: $color-muted !important;
}

.warmi-white-color {
  color: $color-white !important;
}

.text-black {
  color: $color-base !important;
}

.breadcrumb-item {
  color: $color-muted;
}

.border-top-red {
  border-top: 5px solid $color-danger !important;
}

.numberCircle {
  background: $color-danger !important;
}

.text-primary {
  color: $color-primary !important;
}

.text-danger {
  color: $color-danger !important;
}

.border-top-vencido {
  border-top: 5px solid $color-danger-opacity !important;
}

.border-top-vigente {
  border-top: 5px solid $color-primary-opacity !important;
}

.bg-vencido {
  background: $color-danger-opacity !important;
}

.bg-danger {
  background: $color-danger !important;
}

.bg-info-opacity {
  background: $color-primary-opacity !important;
}

.modal-title {
  font-weight: bold !important;
  color: $color-base !important;
}

.menu-item .menu-link {
  color: #5E6278;
}

.menu-item {
  font-weight: normal;
}

.menu-item .menu-link.active {

  //color: #040227;
  i {
    //color: #040227;
  }
}

.menu-item {
  .menu-link:hover {

    //color: #040227 !important;
    i {
      //color: #040227 !important;
    }
  }
}

.switch-toggle input:checked+label {
  border: 2px solid $color-primary !important;

  >i,
  >span {
    color: $color-primary !important;
  }

  svg {
    fill: $color-primary !important;
  }
}

// .nav-link {
//   padding: 0.5rem 0.5rem;
// }

// .nav-link.active {
//   border-bottom: 2px solid $color-primary!important;
//   color: $color-primary;
// }

.fv-plugins-message-container .fv-help-block {
  color: $color-danger
}

.border-danger {
  border-color: $color-danger !important;
}

.border-danger-select .ng-select-container {
  border-color: $color-danger !important;
}

.ng-select-custom.border-danger {
  border-width: 0px !important;

  .ng-select-container {
    border-color: $color-danger !important;
  }
}

.border-info {
  border-color: $color-info !important;
}

th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
  padding-left: 0.8rem !important;
  padding-right: 0.8rem !important;
  text-align: left;

  >div {
    justify-content: left;
  }
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: $color-primary;
}

hr {
  background-color: #ffffff;
  opacity: 0.3;
}

.ff-csm {
  // font-family: "Circular Std Medium" !important;
}

.close {
  cursor: pointer;
}

//nuevo
.min-w-35px {
  min-width: 2.33em !important;
}

.min-w-250px {
  min-width: 17.96em !important;
}

.h-35px {
  // height: 2.33em !important;
}

.h-55px {
  height: 3.66em !important;
}

.mw-35px {
  max-width: 2.33em !important;
}

.h-20px {
  height: 1.33em !important;
}

.w-30px {
  width: 2em !important;
}

.w-150px {
  width: 10.5em !important;
}

.min-w-350px {
  min-width: 24.33em !important;
}

.mw-350px {
  max-width: 24.33em !important;
}

.subtitle {
  font-size: 0.8em !important;
}


// termina nuevo

.h-47-5px {
  height: 47.5px;
}

.h-50px {
  height: 50px;
}

.h-120px {
  height: 120px;
}

.min-h-60px {
  min-height: 4em !important;
}

.minw-35px {
  min-width: 35px !important;
}

.minw-40 {
  min-width: 40%;
}

.mw-40 {
  max-width: 40%;
}

.w-143px {
  width: 143px !important;
}

.w-144px {
  width: 144px !important;
}

.w-160px {
  width: 10.66em;
}

.w-180px {
  width: 180px !important;
}

.w-210px {
  width: 210px !important;
}

.w-229px {
  width: 229px !important;
}

.w-338px {
  width: 338px !important;
}

.w-382px {
  width: 382px !important;
}

.w-400px {
  width: 400px !important;
}

.w-440px {
  width: 440px !important;
}

.mw-425px {
  max-width: 425px;
}

.fs-10px {
  font-size: 10px !important;
}

.fs-11px {
  font-size: 11px !important;
}

.fs-12px {
  font-size: 12px !important;
}

.fs-16px {
  font-size: 1.06em !important;
}

.fs-17px {
  font-size: 17px !important;
}

.fs-20px {
  font-size: 20px !important;
}

.fs-25px {
  font-size: 25px !important;
}

.fs-28px {
  font-size: 28px !important;
}

.fs-35px {
  font-size: 35px !important;
}

.fs-37px {
  font-size: 37px !important;
}

.fs-45px {
  font-size: 3em !important;
}

hr:not([size]) {
  height: 2px;
}

.modal-header {
  padding: 0.5rem 1.75rem;
}

.input-file-hidden {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
}

.p-relative {
  position: relative;
}



.z-index-1060 {
  z-index: 1060 !important;
}

.z-index-1061 {
  z-index: 1061 !important;
}

.input-group-text-custom {
  border: 1px solid #F5F8FA !important;
}

.h-135px {
  height: 135px;
}

.bg-danger-custom {
  background: #FF1C1C;
}

.info-label-action {
  // min-width: 70px;
  // max-width: 70px;
  opacity: 1;
  font-size: 0.73em;
  border-radius: 3px;
  // padding: 2px 3px;
  padding: 1px 2px;
  background: #d1d3d226 0% 0% no-repeat padding-box;
  text-align: center;
  // max-height: 22px;
}

.info-label-action-success {
  background: #06BC8526 0% 0% no-repeat padding-box;
  color: #50cd89;
}

.info-label-action-warning {
  background: #FFCC5A33 0% 0% no-repeat padding-box;
  color: $label-warning-color;
}

.info-label-action-error {
  background: #EC575426 0% 0% no-repeat padding-box;
  color: #f1416c;
}

.info-label-action-moonflow {
  background: $color-primary 0% 0% no-repeat padding-box;
  color: white;
}

.info-label-action-multiple {
  background: #62238250 0% 0% no-repeat padding-box;
  color: #622382;

  i {
    color: #622382;
  }
}

.ml-2px {
  margin-left: 2px;
}

.pill-label {
  border: 2px solid $color-danger;
  color: $color-danger;
  margin-left: 10px;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.pill-label-button {
  border: 2px solid $color-primary;
  background-color: $color-primary;
  color: white;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.margin-left-auto {
  margin-left: auto;
}

.form-check-input {
  width: 1.5rem;
  height: 1.5rem;
}

.form-check:not(.form-switch) .form-check-input[type=checkbox] {
  background-size: 70% 70%;
}


.info-label-moonflow {
  min-width: 75px;
  // max-width: 75px;
  opacity: 1;
  border-radius: 3px;
  padding: 3px 5px;
  background: #d1d3d226 0% 0% no-repeat padding-box;
  text-align: center;
}

.info-label-success-moonflow {
  background: #06BC8526 0% 0% no-repeat padding-box;
  color: $label-success-color;
}

.info-label-warning-moonflow {
  background: #FFCC5A33 0% 0% no-repeat padding-box;
  color: $label-warning-color;
}

.info-label-error-moonflow {
  background: #EC575426 0% 0% no-repeat padding-box;
  color: $label-error-color;
}

.label-success-moonflow-color {
  color: $label-success-color;

  svg {
    fill: $label-success-color;
  }
}

.label-warning-moonflow-color {
  color: $label-warning-color;

  svg {
    fill: $label-warning-color;
  }
}

.label-error-moonflow-color {
  color: $label-error-color;

  svg {
    fill: $label-error-color;
  }
}

.popover .popover-arrow {
  display: none;
}

.popover-body {
  padding: 5px 10px;
  background: #F3EDF7;
  color: #040227;
  font-size: 0.8em !important;
  text-align: start !important;
  border-radius: 5px;
  box-shadow: 0px 1px 2px 0px rgba(4, 2, 39, 0.15), 0px 2px 6px 2px rgba(4, 2, 39, 0.06);

  span,
  b {
    color: inherit !important;
    font-size: inherit !important;
  }
}

i {
  font-size: 0.8em !important;
}

.mat-snack-bar-container {
  background: #040227;
  color: white;
}

.mat-snack-bar-container.success{
  background-color: #01A024;
  color: #FFFFFF;
}

.mat-snack-bar-container.warming{
  background: #FFFAD2;
  border: 1px solid #F9DD10;
  color: #040227;
}

.mat-snack-bar-container.error{
  border: 1px solid #FF1C1C;
  background: #FF1C1C;
  color: #FFFFFF;
}

.mat-snack-bar-container.information{
  border: 1px solid #3E97FF;
  background: #D2E7FF;
  color: #040227;
}

.mat-simple-snackbar {
  justify-content: center !important;
}

// .input-search{
//   min-width: 16.46em !important;max-width: 16.46em !important;
//  }

input.form-control {
  border: 1px solid $background-color-border-custom;
  background: $background-color-border-custom!important;
  font-weight: 400;
  font-size: 1em;
}

.form-control.input-white{
  background-color:#FFF !important;
  border: 1px solid #B3B2BD !important;
}

.form-control.input-white:disabled{
  border: 1px solid #E1E3EA!important;
  background: #DADADA!important;
  box-shadow: none!important;
}

.form-control.input-white.border-danger{
  border: 1px solid #FF1C1C!important;
}

input.form-control:hover:not(.disabled):not(:disabled) {
  border: 1px solid $color-input-hover;
  box-shadow: 0 1px 0 rgb(0 0 0 / 6%) !important;
}

input.form-control:focus {
  border: 1px solid $color-input-focus;
  background: white !important;
}

textarea.form-control {
  border: 1px solid $background-color-border-custom;
  background: $background-color-border-custom !important;
}

textarea.form-control:hover {
  border: 1px solid $color-input-hover;
  box-shadow: 0 1px 0 rgb(0 0 0 / 6%) !important;
}

textarea.form-control:focus {
  border: 1px solid $color-input-hover;
  background: white !important;
}

.input-group-text {
  background: $background-color-border-custom !important;
}

.mat-date-range-input {
  padding: 0.3rem 0.5rem;
  max-width: 14em;
  border: 1px solid $background-color-border-custom !important;
  background: $background-color-border-custom !important;
}

.mat-date-range-input:hover {
  border: 1px solid $color-input-hover !important;
  box-shadow: 0 1px 0 rgb(0 0 0 / 6%) !important;
}

.mat-date-range-input:focus {
  border: 1px solid $color-input-hover !important;
  background: white !important;
  box-shadow: 0 1px 0 rgb(0 0 0 / 6%) !important;
}

.input-group {
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 0.475rem !important;
  border: 1px solid $background-color-border-custom;

  input {
    border: 0 !important;
  }

  .input-group-text {
    border: 0 !important;
  }
}

.input-group:not(.border-danger) {
  border: 1px solid $background-color-border-custom !important;
}

.input-group:hover:not(.disabled) {
  border: 1px solid $color-input-hover !important;
  box-shadow: 0 1px 0 rgb(0 0 0 / 6%) !important;
}

.input-group:focus-within {
  border: 1px solid $color-input-hover !important;

  .input-group-text {
    background-color: white !important;
  }

  box-shadow: 0 1px 0 rgb(0 0 0 / 6%) !important;
}



//-------------------------------------- new design
.tooltip-inner {
  font-size: 0.8em !important;
  text-align: start !important;
}

.horizontal-divider {
  border-bottom: 1px solid #D9D9DE;
  width: 100%;
}

.ellipsis-truncate {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}

$separatorBase : 0.35rem;

.margin-separator {
  margin-bottom: $separatorBase !important;
}

.marginy-separator {
  margin-bottom: $separatorBase !important;
  margin-top: $separatorBase !important;
}

.marginy-separator-padding-height {
  margin-top: calc(#{$separatorBase} + 0.75rem + 1px) !important;
  margin-bottom: calc(#{$separatorBase} + 0.75rem + 1px) !important;
}

.margin-separator-with-padding {
  margin-bottom: calc(#{$separatorBase} + 0.75rem + 1px) !important;
}

.margin-separator-with-padding-top {
  margin-top: calc(#{$separatorBase} + 0.75rem + 1px) !important;
  margin-bottom: $separatorBase !important;
}

.padding-vertical-separator {
  padding-bottom: $separatorBase !important;
  padding-top: $separatorBase !important;
}

.padding-vertical-separator-top {
  padding-top: $separatorBase !important;
}

.padding-vertical-separator-bottom {
  padding-bottom: $separatorBase !important;
}

.padding-vertical-separator-bottom-input {
  padding-bottom: calc(#{$separatorBase} + 0.25rem + 1px) !important;
}

.padding-vertical-separator-top-input {
  padding-top: calc(#{$separatorBase} + 0.25rem + 1px) !important;
}

.padding-vertical-separator-with-lastinput {
  padding-top: $separatorBase !important;
  padding-bottom: calc(#{$separatorBase} + 0.25rem + 1px) !important;
}

.padding-vertical-separator-without-inputs {
  padding-top: calc(#{$separatorBase} + 0.25rem + 1px) !important;
  padding-bottom: calc(#{$separatorBase} + 0.25rem + 1px) !important;
}

.col-form-label {
  padding-top: calc(#{$separatorBase} + 1px);
  padding-bottom: calc(#{$separatorBase} + 1px);
  color: #33475B;
}

.btn.btn-icon.btn-sm,
.btn-group-sm>.btn.btn-icon {
  height: calc(1em + 1.1rem + 2px);
  width: calc(1em + 1.1rem + 2px);
}

#kt_content {
  height: calc(100vh - 60px - 70px);

  #kt_post {
    max-height: 100%;
    height: 100%;
    overflow-y: scroll;
  }
}

.page-description-container {
  margin-top: 0.25rem !important;
}

.below-sub-header-static {
  margin-top: 1.5rem;
}

.bg-moonflow-custom {
  background-color: $background-color-border-custom !important
}

.templates-container-body-dashed {
  border: 1px dashed #9B9AA9 !important;
  background: $background-color-border-custom 0% 0% no-repeat padding-box;
}

.templates-container-body-not-dashed {
  border: 1px solid #E5E5E9 !important;
  background: #ffffff 0% 0% no-repeat padding-box;

  .text-truncate-title {
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: bottom;
    white-space: nowrap;
    max-width: 400px;
  }
}

.templates-preview-bacground-color {
  background-color: #f7f7f8;
}

.nav-tabs {
  .nav-item {
    .nav-link {
      //padding-bottom: 5px !important;
    }
  }
}

.tooltip-inner {
  color: #7b7e8f;
  font-size: 0.8em !important;
}

.card-body-drop-zone {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

//custom-aside
#kt_aside.aside {
  .menu-link-detail {
    display: none;
  }

  .unminimized {
    display: none;
  }

}

// #kt_aside.aside:hover{
//   width: 250px;
//   #kt_aside_logo{
//     width: calc(100% - 28px);
//     padding: 0;
//     margin-left: 28px;
//     .unminimized{
//       height: calc(1.5em + 1.5rem + 2px);
//       display: flex;
//       justify-content: flex-start;
//       align-items: center;
//     }
//     .left-icon{
//       width: 19.5px;
//       height: 26px;
//     }
//     .right-icon{
//       max-height: 26px;
//       max-width: 62%;
//       margin-left: 1.04rem;
//     }
//   }
//   div.minimized{
//     display: none;
//     // animation: fade-out-logo 2s;
//   }
//   span.unminimized{
//     display: flex;
//     animation: fade-in-logo-with-text 2s;
//     .left-icon,.right-icon{
//       display: block;
//     }
//     .right-icon{
//       animation: fade-in-logo 2s;
//     }
//   }
//   .menu-item{
//     .menu-link{
//       width: calc(100% - 15px - 15px);
//     }
//   }
//   .menu-item .menu-link.active{
//     background: #ffffff2c;
//     .menu-icon{
//       background: none !important;
//     }
//   }
//   .menu-link-detail{
//     display: unset;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     white-space: nowrap;
//     vertical-align: middle;
//   }
//   .aside-menu{
//     width: unset !important;
//   }
//   transition-delay: 2s;
// }

@keyframes fade-in-logo {
  0% {
    opacity: 0;
  }

  99% {
    opacity: 0;
  }
}

@keyframes fade-in-logo-with-text {
  0% {
    display: none;
  }

  99% {
    display: flex;
  }
}

@keyframes fade-out-logo {
  0% {
    display: block;
  }

  99% {
    display: none;
  }
}

.svg-pro-icon {
  width: 22px;
  height: 22px;

  svg {
    fill: #ffffff;
  }
}

.card-moonflow-sticky {
  position: sticky;
  top: calc(#{$min-height-header-static} + 0.2rem)
}

.column-action-detail {
  justify-content: start !important;
  text-align: start !important;

  .custom-th {
    padding-left: 0.8rem !important;
    padding-right: 0.8rem !important;
    justify-content: start !important;
    text-align: start !important;
  }
}

.common-template-container {
  .template-list-container {
    max-height: calc(100vh - 16.5em);

    .card-body {
      overflow-y: auto;
    }
  }
}

.previsualization-card {
  &>.card-body {
    // max-height: calc(100vh - 16.5em);
    // height: calc(100vh - 16.5em);
    max-height: calc(100vh - 20em);
    height: calc(100vh - 20em);
    overflow-y: auto;

    .phone-messages:not(.phone-content) {
      max-height: 26em;
    }

    app-preview-mobile {
      .phone-messages.phone-content {
        height: auto;
        min-height: 29em;
        padding-bottom: 15px;

        .main {
          min-height: 29em;

          section {
            padding-top: 2.5vh;
          }
        }
      }
    }

    app-template-email {
      .template-email-image {
        height: 250px;
        max-height: 250px;
        object-fit: cover;
      }

      .template-email-content {
        max-height: 18vh;
        overflow-y: auto;
      }

      .template-email-footer {
        max-height: 12vh;
        overflow-y: auto;
      }
    }
  }
}

.global-modal-position {
  .modal-dialog {
    padding-top: 12px;

    .modal-body {
      max-height: calc(70vh - 12px);
    }
  }
}

.margin-top-modal .modal-dialog{
  margin-top: 12vh !important;
}

.modal-position-top{
  .modal-dialog {
    padding-top: 110px !important;

    .modal-body {
      max-height: calc(70vh - 110px);
    }
  }
}

.xxl-modal-position {
  .modal-dialog {
    //padding-top: 12px;
    .modal-body {
      max-height: calc(79vh - 10px)
    }
  }
}

.modal-radius {
  .modal-content {
    border-radius: 16px;
  }
}

.message-with-outer-html {

  img,
  svg {
    max-width: 100%;
  }
}

.color-moonflow-primary {
  color: $color-primary;
}

.color-moonflow-primary-hover,
.color-moonflow-primary-hover:hover {
  color: $color-primary;
}

.text-underline-hover,
.text-underline-hover:hover {
  text-decoration: underline !important;
}

.fa,
.far,
.fas {
  font-family: "Font Awesome 5 Free" !important;
}


.pill-moonflow {
  line-height: 0;
  padding: 0.4rem;
  background: $color-secondary;
  border-radius: 10px;
  color: white;
}

.text-underline {
  text-decoration: underline;
}

.card-footer.previsualization-card-footer {
  padding: 1rem;
  background-color: #f7f7f8;
  border-top: 0;
}

.preview-tags-description-popover {
  min-width: 350px;
}

.ng-select-marketplace.ng-dropdown-panel .scroll-host {
  box-shadow: 0px 4px 4px 0px rgba(45, 62, 80, 0.12), 0px 1px 5px 0px rgba(45, 62, 80, 0.12) !important;
}

.w-15{width: 15%;}
.w-20{width: 20%;}
.w-30 {width: 30%}
.w-32{width: 32%;}
.w-40 {width: 40%;}
.w-45{width: 45%;}
.w-60{width: 60%;}
.w-65{width: 65%;}
.w-68{width: 68%;}
.w-70{width: 70%;}
.w-85 {width: 85% !important;}
.w-90{width: 90%;}

.width-180px {
  width: 190px !important;
}

.width-90px {
  width: 90px !important;
}

.btn.btn-actions {
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  box-shadow: none;
}

.btn.btn-actions:hover {
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0px 3px 10px 0px rgba(4, 2, 39, 0.10);
}

.fw-600{
  font-weight: 600 !important;
}

.fw-700{
  font-weight: 700 !important;
}

.color-text {
  color: $color-primary !important;
}

.introjs-tooltipbuttons {
  border-top: none !important;
  padding: 15px !important;
  text-align: right;
  white-space: nowrap;
}

.button-tutorial-next {
  box-sizing: content-box;
  position: relative;
  overflow: visible;
  padding: .7rem 1rem;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  white-space: nowrap;
  cursor: pointer;
  outline: 0;
  border-radius: .2em;
  zoom: 1;
  display: inline;
  border-radius: 5px;
  min-width: 94px;
}

.introjs-nextbutton {
  border: 1px solid #bdbdbd !important;
  color: white;
  background: $color-principal-onboarding !important;
  box-shadow: 0px 2px 8px 0px rgba(4, 2, 39, 0.12) !important;
}

.introjs-nextbutton-full {
  width: -webkit-fill-available !important;
}

.tutorial-style {
  height: 46.55px !important;
}

.introjs-nextbutton:hover {
  color: white !important;
  background: $color-button-hover-onboarding !important;
  box-shadow: 0px 2px 6px 0px rgba(4, 2, 39, 0.12) !important;
}

.introjs-nextbutton:active {
  background: $color-button-hover-onboarding !important;
}

.introjs-prevbutton {
  color: #5F6368;
  font-weight: 500;
  border-radius: 5px;
  background: #FFF;
  ;
}

.introjs-prevbutton:hover {
  color: #5F6368;
  background: var(--White, #FFF);
  box-shadow: 0px 3px 10px 0px rgba(4, 2, 39, 0.10);
}

.title-tutorial-container {
  width: 312px;
  height: 95px;
  padding-left: 10px !important;
  padding-right: 10px !important;
  border-radius: 5px;
  background: #F3EDF7;
}

.img-title-tutorial {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.img-logo-tutorial {
  display: flex;
  justify-content: center;
  text-align: center;
}

.custom-tutorial-tooltip {
  min-width: 332px !important;
  max-width: 350px !important;
  border-radius: 16px !important;
}

.custom-tutorial-tooltip-integrations {
  min-width: 332px !important;
  max-width: 350px !important;
  left: 280px !important;
  border-radius: 16px !important;
  padding-top: 10px !important;
}

.custom-tutorial-tooltip .introjs-tooltip-header {
  padding-top: 20px !important;
}

.custom-simple-tutorial-header {
  padding-right: 0px !important;
}

.custom-simple-tutorial-buttons {
  padding-right: 20px !important;
}

.custom-simple-tutorial-text {
  padding-bottom: 8px !important;
}

.tutorial-no-scroll {
  overflow-y: visible !important;
}

.custom-tutorial-title-text {
  color: #040227;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.custom-tutorial-intro-text {
  color: #5F6368;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 8px;
}

.custom-tutorial-intro-subtitle {
  color: #040227 !important;
}

.introjs-arrow {
  border: 6px solid transparent !important;
}

.introjs-arrow.left {
  left: -11px !important;
  top: 17px !important;
  border-right-color: #fff !important;
}

.introjs-arrow.right {
  right: -11px !important;
  top: 17px !important;
  border-left-color: #fff !important;
}

.introjs-arrow.left-bottom {
  left: -11px !important;
  bottom: 90px !important;
  border-right-color: #fff !important;
}

.introjs-arrow.bottom {
  bottom: -11px !important;
  left: 166px !important;
  border-top-color: #fff !important;
}

.introjs-arrow.bottom {
  border-top-color: #fff !important;
}

.introjs-arrow.top-right {
  top: -11px !important;
  right: 11px !important;
  border-bottom-color: #fff !important;
}

.introjs-helperLayer {
  box-shadow: rgba(33, 33, 33, 0.8) 0px 0px 0px 0px, rgba(33, 33, 33, 0.5) 0px 0px 0px 5000px !important;
}

.steps-tutorial-text {
  color: #5F6368 !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
}

.span-intro-tutorial-text {
  color: #040227;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.span-intro-tutorial-email {
  color: #5F6368;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
}

.bullet-step {
  background: #5A4DE5;
  box-shadow: 0px 4px 8px 3px rgba(4, 2, 39, 0.15), 0px 1px 3px 0px rgba(0, 0, 0, 0.30);
  color: white;
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-right: 5px;
  position: relative;
  top: 20px;
  right: 100px;
  cursor: pointer;
}

.bullet-step:hover {
  border-radius: 16px;
  background: #7C71F0;
  box-shadow: 0px 4px 8px 3px rgba(4, 2, 39, 0.15), 0px 1px 3px 0px rgba(0, 0, 0, 0.30);
}

::ng-deep #hubspot-messages-iframe-container{
  display: none!important;
}
.otherSessionProgress{
  color: $color-secundary-text;
  font-size: 15px;
  font-weight: 500;
}
