
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$demo1-primary: mat.define-palette(mat.$indigo-palette);
$demo1-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$demo1-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$demo1-theme: mat.define-light-theme((
  color: (
    primary: $demo1-primary,
    accent: $demo1-accent,
    warn: $demo1-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($demo1-theme);

/* You can add global styles to this file, and also import other style files */
@import "./assets/sass/style";



// Replace above style with this SCSS file to enable dark mode styles
//@import "./assets/sass/style.dark";

@import "src/assets/sass/moonflow/recipient-type.scss";

html, body { height: 100%; }
body {
  margin: 0;
}

.numeric-field {
  text-align: right;
}

 .cdk-overlay-container{ z-index: 9000 !important; }

 .cell-align-left{
  text-align: left;
 }

 .cell-align-right{
  text-align: right !important;
 }

 @media (max-width: 991px) {
  .mt-md-0px{
    margin-top: 0px !important;
  }
  .mb-md-0px{
    margin-bottom: 0px !important;
  }
  .h-15rem{
    height: auto !important;
    min-height: 31vh !important;
  }
}

.rm-hover:hover{
  scrollbar-color: inherit;
}

 ::-webkit-scrollbar {
  width: 9px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #2c158f36;
  border-radius: 20px;
  border: transparent;
}

.col-10-5 {
  flex: 0 0 auto;
  width: 87.50000000000001%;
}

.col-10-75 {
  flex: 0 0 auto;
  width: 86.11111111111111%;
}

.mh-auto-important{
  min-height: auto !important;
}

.modal-body{
  max-height: 550px;
  overflow: scroll;
}

.card-fixed{
  position: sticky;
  background: white;
  z-index: 99;
  top: calc(65px + 1rem);
}

.height-card{
  height: calc(100vh - 95px)
}

.max-height-card{
  max-height: calc(100vh - 95px)
}

.max-height-card-with-labels{
  max-height: calc(100vh - 109px)
}

.overflow-y-auto{
  overflow-y: auto;
}

.example-container {
  position: relative;
  min-height: 200px;
  max-height: calc(100vh - 25rem);
  overflow: auto;
}

/* .safe-mode-active .example-container{
  max-height: calc(100vh - 23.5rem - 40px);
}

.oneNotificacion .example-container {
  max-height: calc(100vh - 23.5rem - 40px);
}

.twoNotificacion .example-container {
  max-height: calc(100vh - 23.5rem - 80px);
}

.threeNotificacion .example-container {
  max-height: calc(100vh - 23.5rem - 120px);
}
.fourNotificacion .example-container {
  max-height: calc(100vh - 23.5rem - 160px);
}
.fiveNotificacion .example-container {
  max-height: calc(100vh - 23.5rem - 200px);
}

.oneNotificacion.safe-mode-active .example-container {
  max-height: calc(100vh - 23.5rem - 40px - 40px);
}
.twoNotificacion.safe-mode-active .example-container {
  max-height: calc(100vh - 23.5rem - 80px - 40px);
}
.threeNotificacion.safe-mode-active .example-container {
  max-height: calc(100vh - 23.5rem - 120px - 40px);
}
.fourNotificacion.safe-mode-active .example-container {
  max-height: calc(100vh - 23.5rem - 160px - 40px);
}
.fiveNotificacion.safe-mode-active .example-container {
  max-height: calc(100vh - 23.5rem - 200px - 40px);
} */

th.mat-header-cell {
  background: white;
  padding: 0!important;
  z-index: 98!important;
}
.custom-th{
  padding-left: 0.8rem !important;
  // padding-right: 0.8rem !important;
  height: 100%;
  display: flex!important;
  align-items: end;
}

.page-configuration-customized{
  .custom-th{
    white-space: nowrap;
  }
}

.example-loading-shade {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  // background: rgba(112, 112, 112, 0.15);
  z-index: 100 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.background-png{
  background-image: linear-gradient(45deg,#efefef 25%,rgba(239,239,239,0) 25%,rgba(239,239,239,0) 75%,#efefef 75%,#efefef),linear-gradient(45deg,#efefef 25%,rgba(239,239,239,0) 25%,rgba(239,239,239,0) 75%,#efefef 75%,#efefef);
  background-color: #fff;
  background-position: 0 0,10px 10px;
  background-size: 21px 21px;
}

.pl-1-5rem{
  padding-left: 1.5rem;
}



.d-inline-block-data{
  display: inline-block !important;
}

.bullet-point{
  background-color: #2C158F;
  color: white;
  border-radius: 50%;
  padding: 2px 5px!important
}

.bullet-point-sm{
  line-height: 1.5;
  font-size: 0.8em !important;
  padding: 1px 1px 1px 0.5px !important;
  text-align: center;
  max-height: 16px!important;
  min-height: 16px!important;
  min-width: 16px!important;
  max-width: 16px!important;
  display: flex;
  justify-content: center;
  align-items: center;
}
// colores anteriores
.bg-client{
  //background-color: #09041C!important;
}
.bg-referents{
  //background-color: #2C158F!important;
}
.bg-guarantors{
  //background-color: #4E25FF!important;
}

.bullet-point.bg-client{
  background-color: white!important;
  color: $recipient-type-client !important;
  border: 1px solid $recipient-type-client !important;
}
.bullet-point.bg-referents{
  background-color: white!important;
  color: $recipient-type-referent !important;
  border: 1px solid $recipient-type-referent !important;
}
.bullet-point.bg-guarantors{
  background-color: white!important;
  color: $recipient-type-guarantor !important;
  border: 1px solid $recipient-type-guarantor !important;

}


@media (max-width: 1540px) {
  .bullet-point-sm {
  max-height: 17px!important;
  min-height: 17px!important;
  min-width: 17px!important;
  max-width: 17px!important;
  padding: 2px 5px!important;
  }
}

.btn-link:hover{
  text-decoration: underline!important;
 }

 .btn-tag-emoji {
  color: #e0e0e0;
 }

 .font-weight-bold-style{
  font-weight: bold;
}

.card > .card-header > h3.card-title, .card > .card-body .col-form-label h3.card-title{
  font-size: 14px !important;
}

.custom-horizontal-divider{
  width: 100%;
  border-bottom: 1px solid #EFF2F5;
  min-height: 1px;
}

@-webkit-keyframes rightPush {
  0% {
    margin-right: 0px;
  }

  100% {
    margin-right: 6px;
  }
}

@keyframes rightPush {
  0% {
    margin-right: 0px;
  }

  100% {
    margin-right: 6px;
  }
}


.aside-custom{
  height: 45px;
  min-width: 130px;
  padding: 0px;
  background: #ffffff2c;
  //transition: all 0.2s ease-in;
  .tooltip-inner{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;

  }
}

.visibility-hidden{
  visibility: hidden;
}


.skeleton-is-loading {
  .image,
  h3,
  p {
    background: #F2F4FA;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
  }

  .image {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  h2 {
    height: 30px;
  }

  p {
    height: 21px;
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.form-label-disabled{
  color: #C3C3C3 !important;
  opacity: 0.4;
}