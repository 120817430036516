@import "src/assets/sass/_base-moonflow.scss";

$monnflow-modal-padding-left : 24px;
$monnflow-modal-padding-right : 24px;
$monnflow-modal-button-footer-min-width : 156px;
$monnflow-modal-button-footer-min-height : 42px;

.modal-body{
  .btn-action{
    background-color: #e5e5e900;
    margin-right: 0.25rem !important;
    i{
      font-size: 1em!important;
    }
  }
  .btn-action:hover{
    background-color: #E5E5E9;
    i{
      color: #040227;
    }
  }
}

.modal-body, .modal-footer{
    padding-left: $monnflow-modal-padding-left;
    padding-right: $monnflow-modal-padding-right;
}

.modal-footer{
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-top: 0px !important ;
  .button-footer-ok, .button-footer-cancel{
    min-width: $min-width-btn;
    text-align: center;
    justify-content: center;
    padding: $padding-btn-custom;
  }
  .button-footer-ok{
    background-color: $color-primary;
    color: #fff;
    display: inline-flex;
    align-items: center;
    height: $min-height-btn-custom;
    border-radius: 5px;
    min-width: $min-width-btn;
  }
  .button-footer-cancel{
    color: $color-primary ;
    background-color: white;
  }
}



@media (min-width: 576px) {
    .modal-dialog {
      max-width: 545px;
    }

    .modal-variables{
      max-width: 620px;
    }

    .modal-sm {
      max-width: 264px;
    }

    .modal-loading {
      max-width: 264px;
      max-height: 264px;
    }

    .modal-confirm {
      max-width: 320px;
    }

    .modal-md {
      max-width: 355px;
    }
    .modal-std {
      max-width: 400px;
    }
    .modal-mtd{
      max-width: 430px;
    }
    .modal-lg,
    .modal-xl {
      max-width: 800px;
    }
    .modal-xxl {
      max-width: 1100px;
    }
    .modal-80{
      max-width: 80%;
    }
    .modal-xxxl{
      max-width: 100%;
      .modal-dialog{
        max-width: 90%;
        .modal-body{
          max-height: unset;
        }
      }
    }
  }
