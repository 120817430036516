.card {
  border: 1px solid #E5E5E9;
  border-radius: 5px;
  box-shadow: none;
}

.card-header {
  .card-title{
    .card-label{
      font-weight: bold!important;
    }
    font-weight: bold!important;
  }
  min-height: 3em !important;
}

.card-border-0{
  border: none!important;
  .card-header{
    padding-right: 0 !important;
    padding-left: 0 !important;
    .card-title{
      justify-content: center;
      flex-direction: column !important;
      .description{
        // white-space: nowrap !important;
        font-weight: 500;
      }
    }
  }
}

.card-body-custom {
  padding: 0!important;
}
