// $color-primary: #2C158F;
$color-primary: #040227;
$color-base:#040227;
$color-white: #fff;
$color-secundary-text: #33475B;
// $color-muted: #A1A5B7;
$color-muted: #7b7e8f;
$color-warning: #FF734D;
$color-danger: #BF3B6C;
$color-info: #7A02F2;
$color-primary-opacity: #2c158f36;
$color-danger-opacity: #bf3b6c4f;
$color-secondary: #574FE8;
$border-default-color : #E5E5E9;
$background-color-border-custom : #f7f7f8;
$disabled-color: #7C7C7C;
$padding-btn-custom: 7px 7px;
$min-height-btn-custom : 35px;
$margin-between-switches : 5px;
$min-width-btn : 100px;
$color-messages : #7b7e8f;
$label-success-color : #06BC85;
$label-warning-color : #F7B523;
$label-error-color : #EB1D1D80;
$color-input-hover: #B3B2BD;
$color-input-focus: #5F6368;
$color-principal-onboarding : #5A4DE5;
$color-button-hover-onboarding : #7C71F0;
$color-checkbox-default-onboarding : #ACA5F1;


$font-size-10px: 10px;
$font-size-12px: 12px;
$font-size-14px: 14px;
$font-size-18px: 18px;
$font-size-20px: 20px;
